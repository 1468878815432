.btn,
.jumbotron,
.nav :hover {
  border-radius: 0px;
}

.product-img {
  min-height: 100px;
}

.jumbotron h2 {
  margin-top: -20px;
}

@media only screen and (max-width: 600px) {
  .jumbotron h2 {
    margin-top: 10px;
  }
}

/* jumbotronn amimation */
.jumbotron {
  width: 30wh;
  height: 30vh;
  color: #fff;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: Gradient 15s ease infinite;
  -moz-animation: Gradient 15s ease infinite;
  animation: Gradient 15s ease infinite;
}

@-webkit-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@-moz-keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


/* cart badge */

.cart-badge,
.cart-badge:hover {
    border-radius: 50%;
    padding: 2px;
    font-size: 12px;
    font-style: italic;
    background: #000;
}


/**
* single product page - product name
*/

.name {
  background: indigo;
  color: #fff;
  font-weight: bold;
}


/* black shade form 10-1 */
.black-10 {
  background: #f2f2f2;
}
.black-9 {
  background: #e6e6e6;
}
.black-8 {
  background: #d9d9d9;
}
.black-7 {
  background: #cccccc;
}
.black-6 {
  background: #bfbfbf;
}
.black-5 {
  background: #b3b3b3;
}


img.center {
  display: block;
  margin: 0 auto;
}



/* Hide the scrollbar track */
::-webkit-scrollbar {
  width: 5px;
}

/* Define the scrollbar track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Define the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* On hover, style the thumb */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}